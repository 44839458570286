<template>
  <div>
    <div class="row mt-60">
      <div class="col-lg-10 col-md-12">
        <h2 class="text-primary">Tiles Details</h2>
        <p class="text-dark">
          More than one tile can be added by
          clicking on this button
        </p>
      </div>
      <div class="col-lg-2 col-md-12 text-left text-lg-right">
        <button class="btn btn-add-success" type="button" @click="addMoreTitle">
          + Add Tiles
        </button>
      </div>
    </div>
    <div class="bg-white py-40 mt-40">
      <div v-for="(_kTitle, _index) in titles" :key="_index" class="mb-30">
        <div class="row no-gutters p-lg-0 p-20">
          <div class="col-lg-7 col-md-12 m-auto">
            <h3 class="text-primary mb-4">
              <span>{{ _index + 1 | ordinal }}</span> Tile
              <div class="d-inline-flex float-right">
                <button
                  v-if="titles.length > 1"
                  class="btn btn-add-success float-right"
                  @click="removeFromChildForm(titles, _index, 'api', welcomeContentsState)"
                >
                  <i aria-hidden="true" class="fa fa-times"></i> Close
                </button>
              </div>
            </h3>
            <div class="form-group">
              <div class="form-row">
                <div class="col-md-12 col-lg-4">
                  <label class="col-form-label">Tenet Number</label>
                </div>
                <div class="col-md-12 col-lg-8">
                  <input
                    v-model="_kTitle.order"
                    class="admin-input form-control" name="name"
                    placeholder="Type tenet Number"
                    type="number"
                  />
                  <div
                    v-if="$v.$error & !$v.titles.$each.$iter[_index].order.required"
                    class="error">
                    Tenet number is required.
                  </div>
                  <div
                    v-if="$v.$error & !$v.titles.$each.$iter[_index].order.numeric"
                    class="error">
                    Only positive number is acceptable.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mt-30">
              <div class="form-row">
                <div class="col-md-12 col-lg-4">
                  <label class="col-form-label">Upload Photograph</label>
                </div>
                <div class="col-md-12 col-lg-8">
                  <div class="row no-gutters">
                    <div class="col-lg-8 p-0">
                      <div class="dashed_upload">
                        <div class="wrapper">
                          <div class="drop drop-icon">
                            <div class="cont">
                              <img
                                v-if="_kTitle.photo && _kTitle.photo.previewUrl"
                                :src="_kTitle.photo && _kTitle.photo.previewUrl"
                                alt="Cover Photo" class="icon-preview"/>
                              <img
                                v-else-if="_kTitle.photo && photoPreviewMapping[_kTitle.photo.tsync_id]"
                                :src="photoPreviewMapping[_kTitle.photo.tsync_id]"
                                alt="Cover Photo" class="icon-preview"/>
                              <img
                                v-else-if="_kTitle.photo && !_kTitle.photo.previewUrl && !_kTitle.photo.relative_url"
                                alt="" src="@/assets/img/icons/upload1.svg">
                              <img
                                v-else
                                :src="_kTitle.photo && _kTitle.photo.relative_url | makeAbsUrl" alt="Cover Photo"
                                class="icon-preview"/>
                              <div v-if="_kTitle.photo && !_kTitle.photo.name" class="desc"> Drop files here or
                                click to
                                upload
                              </div>
                              <div v-if="_kTitle.photo && _kTitle.photo.name" class="desc">
                                {{ _kTitle.photo.name | textTruncate(15) }}
                              </div>
                            </div>
                            <input
                              accept="image/*"
                              type="file"
                              @change="onImageChange($event,_index)"
                            />
                            <div
                              v-if="$v.$error & !$v.titles.$each.$iter[_index].photo.tsync_id.required"
                              class="error">
                              Cover image is required.
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- End: Drag & Drop Upload Form -->
                    </div>
                    <div class="col-md-4">
                      <div v-if="_kTitle.photo && _kTitle.photo.name" class="d-inline-flex">
                        <i aria-hidden="true" class="fa fa-times ml-2 clear-image"
                           @click="clearCoverImage(titles, _index)"> Clear</i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mt-30">
              <div class="form-row">
                <div class="col-md-12 col-lg-4">
                  <label class="col-form-label">Icon</label>
                </div>
                <div class="col-md-12 col-lg-8">
                  <div class="row no-gutters">
                    <div class="col-lg-8 p-0">
                      <div class="dashed_upload">
                        <div class="wrapper">
                          <div class="drop drop-icon">
                            <div class="cont">
                              <img
                                v-if="_kTitle.icon && _kTitle.icon.previewUrl"
                                :src="_kTitle.icon && _kTitle.icon.previewUrl" alt="Icon" class="icon-preview"/>
                              <img
                                v-else-if="_kTitle.icon && iconPreviewMapping[_kTitle.icon.tsync_id]"
                                :src="iconPreviewMapping[_kTitle.icon.tsync_id]" alt="Icon" class="icon-preview"/>
                              <img
                                v-else-if="_kTitle.icon && !_kTitle.icon.previewUrl && !_kTitle.icon.relative_url"
                                alt="" src="@/assets/img/icons/upload1.svg">
                              <img
                                v-else :src="_kTitle.icon && _kTitle.icon.relative_url | makeAbsUrl" alt="Icon"
                                class="icon-preview"/>
                              <div v-if="_kTitle.icon && !_kTitle.icon.name" class="desc"> Upload Icon</div>
                              <div v-if="_kTitle.icon && _kTitle.icon.name" class="desc">
                                {{ _kTitle.icon.name | textTruncate(15) }}
                              </div>
                            </div>
                            <input
                              accept="image/*"
                              type="file"
                              @change="onIconChange($event,_index)"
                            />
                            <div
                              v-if="$v.$error & !$v.titles.$each.$iter[_index].icon.tsync_id.required"
                              class="error">
                              Icon is required.
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- End: Drag & Drop Upload Form -->
                    </div>
                    <div class="col-md-4">
                      <div v-if="_kTitle.icon && _kTitle.icon.name" class="d-inline-flex">
                        <i aria-hidden="true" class="fa fa-times ml-2 clear-image"
                           @click="clearIcon(titles, _index)"> Clear</i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mt-30">
              <div class="form-row">
                <div class="col-md-12 col-lg-4">
                  <label class="col-form-label">Title</label>
                </div>
                <div class="col-md-12 col-lg-8">
                  <input
                    v-model="_kTitle.title"
                    class="admin-input form-control"
                    name="name"
                    placeholder="Welcome message Header"
                    type="text"
                  />
                  <div
                    v-if="$v.$error & !$v.titles.$each.$iter[_index].title.required"
                    class="error">
                    Title is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mt-30">
              <div class="form-row">
                <div class="col-md-12 col-lg-4">
                  <label class="col-form-label">Description</label>
                </div>
                <div class="col-md-12 col-lg-8">
                  <textarea
                    v-model="_kTitle.description"
                    class="admin-input form-control"
                    name="name"
                    placeholder="Type full description"
                    rows="5" type="text"
                  ></textarea>
                  <div
                    v-if="$v.$error & !$v.titles.$each.$iter[_index].description.required"
                    class="error">
                    Description is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mt-30">
              <div class="form-row">
                <div class="col-md-12 col-lg-4">
                  <label class="col-form-label">Add URL</label>
                </div>
                <div class="col-md-12 col-lg-8">
                  <input
                    v-model="_kTitle.more_link"
                    class="admin-input form-control"
                    name="name"
                    placeholder="URL"
                    type="text"
                  />
                  <div
                    v-if="$v.$error & !$v.titles.$each.$iter[_index].more_link.required"
                    class="error">
                    More link is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters p-lg-0 p-20">
        <div class="col-lg-7 col-md-12 m-auto">
          <div class="form-group mb-30">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                <div class="text-right mt-30">
                  <div class="d-inline-flex">
                    <button v-if="!isObserverUser" :disabled="!isSaveButtonActive" class="btn btn-submit" type="button"
                            @click="saveTitles">
                      {{ saveButtonTxt }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import UtilityMixin from '@/mixins/utility-mixin'
import { validationMixin } from 'vuelidate'
import { numeric, required } from 'vuelidate/lib/validators'
import { ContentTypes } from '@/config/constants'
import Menus from '@/config/menus'
import _ from 'lodash'

export default {
  name: 'WelcomeCardForm',
  components: {},
  data () {
    return {
      titles: [
        {
          photo: {
            previewUrl: null,
            file: null,
            name: null,
            tsync_id: null
          },
          icon: {
            previewUrl: null,
            file: null,
            name: null,
            tsync_id: null
          },
          description: '',
          more_link: '',
          order: '',
          title: '',
          id: null
        }
      ],
      iconTsyncIdMapping: [],
      iconPreviewMapping: [],
      photoTsyncIdMapping: [],
      photoPreviewMapping: []
    }
  },
  mixins: [FormMixin, validationMixin, UtilityMixin],
  validations: {
    titles: {
      required,
      $each: {
        title: {
          required
        },
        order: {
          required,
          numeric
        },
        description: {
          required
        },
        more_link: {
          required
        },
        icon: {
          tsync_id: { required }
        },
        photo: {
          tsync_id: { required }
        }
      }
    }
  },
  computed: {
    welcomeContentsState () {
      return this.$store.state.Welcome.contents
    },
    contents () {
      return this.$store.getters.getWelcomeContents
    },
    cardContents () {
      const multiWidgetContent = this.$_.head(this.$_.sortBy(this.contents.filter((item) => {
        return item.content_type === ContentTypes.MULTI_WIDGET
      }), 'order'))
      if (multiWidgetContent === undefined) {
        return []
      }
      let _contents = this.contents.filter((item) => {
        return item.content_type === ContentTypes.CARD && item.parent === multiWidgetContent.id
      })
      _contents = _.sortBy(_contents, function (item) {
        return parseInt(item.order, 10)
      })
      return _contents || []
    }
  },
  methods: {
    addMoreTitle () {
      this.titles.push({
        photo: {
          previewUrl: null,
          file: null,
          name: null,
          tsync_id: null
        },
        icon: {
          previewUrl: null,
          file: null,
          name: null,
          tsync_id: null
        },
        description: '',
        more_link: '',
        order: '',
        title: ''
      })
    },
    onIconChange (e, index) {
      try {
        // index => key statistics array objects index
        if (!this.titles[index].icon) {
          this.titles[index].icon = {}
        }
        if (e.target.files.length > 0) {
          const file = e.target.files[0]
          this.titles[index].icon.previewUrl = URL.createObjectURL(file)
          this.titles[index].icon.file = file
          this.titles[index].icon.name = file.name
          this.titles[index].icon.tsync_id = this.uuidV4()
        } else {
          // if icon upload widget clicked and no image selected
          this.titles[index].icon.previewUrl = null
          this.titles[index].icon.file = null
          this.titles[index].icon.name = null
        }
      } catch (e) {
        this.errorLog(e)
      }
    },
    onImageChange (e, index) {
      try {
        // index => key statistics array objects index
        if (!this.titles[index].photo) {
          this.titles[index].photo = {}
        }
        if (e.target.files.length > 0) {
          const file = e.target.files[0]
          this.titles[index].photo.previewUrl = URL.createObjectURL(file)
          this.titles[index].photo.file = file
          this.titles[index].photo.name = file.name
          this.titles[index].photo.tsync_id = this.uuidV4()
        } else {
          // if icon upload widget clicked and no image selected
          this.titles[index].photo.previewUrl = null
          this.titles[index].photo.file = null
          this.titles[index].photo.name = null
        }
      } catch (e) {
        this.errorLog(e)
      }
    },
    async saveTitles () {
      try {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'

        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$notify({
            title: '',
            text: 'Please fix error(s) in form.',
            type: 'error',
            duration: 5000
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
        } else {
          const vm = this
          this.iconTsyncIdMapping = {}
          this.iconPreviewMapping = {}
          this.photoTsyncIdMapping = {}
          this.photoPreviewMapping = {}
          this.fileTsyncIdMapping = {}
          const welcomeCardPromises = []
          this.titles.map((item) => {
            const response = this.submitEachCardContent(item)
            welcomeCardPromises.push(response)
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
          Promise.all(welcomeCardPromises).then((values) => {
            const photoPromises = []
            values.map((item) => {
              const iconId = item.data.icon ? item.data.icon.id : 0
              const iconTsyncId = item.data.icon ? item.data.icon.tsync_id : ''
              if (iconId === 0 || iconTsyncId === '') return
              const iconFile = vm.iconTsyncIdMapping[iconTsyncId]
              if (_.get(iconFile, 'name')) {
                const response = vm.uploadMultipartPhoto(iconId, iconFile)
                photoPromises.push(response)
              }

              const photoId = item.data.photo ? item.data.photo.id : 0
              const photoTsyncId = item.data.photo ? item.data.photo.tsync_id : ''
              if (photoId === 0 || photoTsyncId === '') return
              const photoFile = vm.photoTsyncIdMapping[photoTsyncId]
              if (_.get(photoFile, 'name')) {
                const response = vm.uploadMultipartPhoto(photoId, photoFile)
                photoPromises.push(response)
              }
            })
            Promise.all(photoPromises).then((iconResponses) => {
              this.$notify({
                title: 'Welcome',
                text: 'Tenet card updated successfully.',
                type: 'success', // 'warn', 'error', 'success'
                duration: 5000
              })
            })
          })
        }
      } catch (e) {
        this.isSaveButtonActive = true
        this.saveButtonTxt = 'Submit'
        this.errorLog(e)
      }
    },
    async submitEachCardContent (data) {
      try {
        const vm = this
        const formData = this.$_.cloneDeep(data)
        const iconTsyncId = (formData.icon && formData.icon.tsync_id) ? formData.icon.tsync_id : vm.uuidV4()
        if (_.get(data, 'icon.file')) {
          vm.iconTsyncIdMapping[iconTsyncId] = data.icon.file
        }
        if (data.icon && data.icon.previewUrl) {
          vm.iconPreviewMapping[iconTsyncId] = data.icon.previewUrl
        }
        if (!formData.icon.id && formData.icon.name) {
          formData.icon = {
            tsync_id: iconTsyncId,
            extension: formData.icon.name.split('.').pop()
          }
        } else {
          try {
            formData.icon.extension = formData.icon.name.split('.').pop()
          } catch (e) {

          }
        }

        const photoTsyncId = (formData.photo && formData.photo.tsync_id) ? formData.photo.tsync_id : vm.uuidV4()
        if (_.get(data, 'photo.file')) {
          vm.photoTsyncIdMapping[photoTsyncId] = data.photo.file
        }
        if (data.photo && data.photo.previewUrl) {
          vm.photoPreviewMapping[photoTsyncId] = data.photo.previewUrl
        }
        if (!formData.photo.id && formData.photo.name) {
          formData.photo = {
            tsync_id: photoTsyncId,
            extension: formData.photo.name.split('.').pop()
          }
        } else {
          try {
            formData.photo.extension = formData.photo.name.split('.').pop()
          } catch (e) {

          }
        }
        formData.content = this.$store.getters.getContentByAppModule(Menus.welcome.key).id
        formData.content_type = ContentTypes.CARD
        formData.parent = this.$store.getters.getParentContentItemByContentType(
          ContentTypes.MULTI_WIDGET, Menus.welcome.key).id
        return await this.$store.dispatch('createOrUpdateWelcomeContentItem', formData)
      } catch (e) {
        this.errorLog(e)
      }
    }
  },
  watch: {
    cardContents: {
      deep: true,
      handler (newValue) {
        if (newValue.length > 0) {
          this.titles = _.cloneDeep(newValue)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
textarea.form-control {
  padding-top: 15px;
}

.icon-preview {
  height: 42px;
}

.clear-image {
  cursor: pointer;
}

.drop-sm {
  padding-right: 60px !important;
  max-width: 52%; // if file name is long than it wont overflow next close button
}
</style>
