<template>
  <div>
    <!-- Start: Header -->
    <div class="form-group mb-20">
      <div class="form-row">
        <div class="col-lg-4 col-md-12 col-sm-12 col-12">
          <label
            class="col-form-label"
          >Welcome message
            Header</label
          >
        </div>
        <div class="col-lg-8 col-md-12">
          <input
            v-model="header"
            class="form-control admin-input"
            name="name"
            placeholder="Welcome message Header"
            type="text"
          />
          <div
            v-if="$v.$error & !$v.header.required"
            class="error">
            Header is required.
          </div>
        </div>
      </div>
    </div>
    <!-- End: Header -->
    <!-- Start: Sub Header -->
    <div class="form-group">
      <div class="form-row">
        <div class="col-lg-4 col-md-12">
          <label
            class="col-form-label"
          >Welcome message
            sub-header</label
          >
        </div>
        <div class="col-lg-8 col-md-12">
          <textarea
            v-model="subheader"
            class="form-control admin-input"
            name="name"
            placeholder="Welcome message Header"
            rows="5"></textarea>
          <div
            v-if="$v.$error & !$v.subheader.required"
            class="error">
            Sub header is required.
          </div>
        </div>
      </div>
    </div>
    <!-- End: Sub Header -->

    <div class="form-group mb-30">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-sm-12">
          <div class="text-right mt-30">
            <div class="d-inline-flex">
              <button v-if="!isObserverUser" :disabled="!isSaveButtonActive" class="btn btn-submit" type="button"
                      @click="saveGreetings">
                {{ saveButtonTxt }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import UtilityMixin from '@/mixins/utility-mixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { ContentTypes } from '@/config/constants'
import Menus from '@/config/menus'

export default {
  name: 'WelcomeHeaderInfoForm',
  data () {
    return {
      contentId: undefined,
      header: '',
      subheader: ''
    }
  },
  mixins: [FormMixin, validationMixin, UtilityMixin],
  validations: {
    header: {
      required
    },
    subheader: {
      required
    }
  },
  computed: {
    contents () {
      return this.$store.getters.getWelcomeContents
    },
    greetingsContent () {
      const _content = this.$_.sortBy(this.contents, 'order').find((item) => {
        return item.content_type === ContentTypes.GREETING
      })
      return _content !== undefined ? _content : {}
    }
  },
  methods: {
    async saveGreetings () {
      try {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'

        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$notify({
            title: '',
            text: 'Please fix error(s) in form.',
            type: 'error',
            duration: 5000
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
        } else {
          const _data = {
            id: this.contentId,
            title: this.header,
            subtitle: this.subheader,
            content_type: ContentTypes.GREETING,
            content: this.$store.getters.getContentByAppModule(Menus.welcome.key).id
          }
          const response = await this.$store.dispatch('createOrUpdateWelcomeContentItem', _data)
          if (response.data.success) {
            this.$notify({
              title: 'Welcome',
              text: 'Welcome header and subheader updated successfully.',
              type: 'success', // 'warn', 'error', 'success'
              duration: 5000
            })
          }
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
        }
      } catch (e) {
        this.isSaveButtonActive = true
        this.saveButtonTxt = 'Submit'
        this.errorLog(e)
      }
    }
  },
  watch: {
    greetingsContent: {
      deep: true,
      handler (newValue) {
        newValue = _.cloneDeep(newValue)
        this.contentId = newValue.id
        this.header = newValue.title
        this.subheader = newValue.subtitle
      }
    }
  }
}
</script>

<style lang="scss" scoped>
textarea.form-control {
  padding-top: 15px;
}
</style>
