<template>
  <Welcome></Welcome>
</template>

<script>
import Welcome from '@/components/admin/welcome/Welcome'

export default {
  name: 'WelcomeView',
  components: { Welcome }
}
</script>

<style scoped>

</style>
