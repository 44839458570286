<template>
  <section class="mt-30 admin-welcome-page">
    <bw-loader v-if="loading"/>
    <div class="container" v-show="!loading">
      <div class="row">
        <div class="col">
          <div class="bg-white py-40">
            <div class="row no-gutters p-lg-0 p-20">
              <div class="col-lg-7 col-md-12 m-auto">
                <welcome-header-info-form/>
              </div>
            </div>
          </div>
          <welcome-card-form/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import WelcomeHeaderInfoForm from '@/components/admin/welcome/WelcomeHeaderInfoForm'
import WelcomeCardForm from '@/components/admin/welcome/WelcomeCardForm'
import BwLoader from '@/components/ui/BwLoader'

export default {
  name: 'Welcome',
  components: {
    BwLoader,
    WelcomeCardForm,
    WelcomeHeaderInfoForm
  },
  data () {
    return {
      loading: false
    }
  },
  created () {
    this.getWelcomePageContent()
  },
  methods: {
    async getWelcomePageContent () {
      this.loading = true
      await this.$store.dispatch('getWelcomeContentsFromServer')
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
